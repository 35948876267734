.ui-widget-overlay.ui-front {
    background: rgba(0,0,0,0.5);
    z-index: 999 !important;
}
.ui-front {
    z-index: 1000 !important;
}

/* containers */
.ui-widget {
}
.ui-widget .ui-widget {
    background: white;
}
.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
}
.ui-widget-content {
    background: white;
}
.ui-widget-content a {
}
.ui-widget-header {
}
.ui-widget-header a {
}

/* interaction states */
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
    background: #DDD;
}
.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited {
}
.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover {
    background: #CCC;
}
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
    background: #CCC;
}
.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited {
}
.ui-state-focus a,
.ui-state-focus a:hover,
.ui-state-focus a:link,
.ui-state-focus a:visited {
}
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
}
.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
}

/* interaction cues */
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
}
.ui-state-highlight a,
.ui-widget-content .ui-state-highlight a,
.ui-widget-header .ui-state-highlight a {
}
.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
}
.ui-state-error a,
.ui-widget-content .ui-state-error a,
.ui-widget-header .ui-state-error a {
}
.ui-state-error-text,
.ui-widget-content .ui-state-error-text,
.ui-widget-header .ui-state-error-text {
}
.ui-priority-primary,
.ui-widget-content .ui-priority-primary,
.ui-widget-header .ui-priority-primary {
}
.ui-priority-secondary,
.ui-widget-content .ui-priority-secondary,
.ui-widget-header .ui-priority-secondary {
}
.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
}
.ui-state-disabled .ui-icon {
}

/* corner */
.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
    border-top-left-radius: 2px;
}
.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
    border-top-right-radius: 2px;
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
    border-bottom-left-radius: 2px;
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
    border-bottom-right-radius: 2px;
}

/* autocomplete */
.ui-autocomplete {
    border-radius: 2px;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 200;
}

/* buttons */
.ui-button-text-only .ui-button-text {
    margin: 0;
    padding: 0;
}

/* dialog */
.ui-dialog {
}

/* datepicker */
.ui-datepicker {
    border: 1px solid #BBB;
    padding: 10px;
    width: auto;
}
.ui-datepicker .ui-datepicker-header {
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-next-hover {
    height: auto;
    left: auto;
    right: auto;
    top: auto;
    width: auto;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
}
.ui-datepicker .ui-datepicker-prev {
    left: 0;
}
.ui-datepicker .ui-datepicker-next {
    right: 0;
}
.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
}
.ui-datepicker .ui-datepicker-prev-hover {
}
.ui-datepicker .ui-datepicker-next-hover {
}
.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
    cursor: pointer;
    left: auto;
    line-height: 1em;
    margin: 0;
    padding: 10px 10px;
    position: static;
    text-indent: 0;
    top: auto;
}
.ui-datepicker .ui-datepicker-title {
    line-height: 1em;
    margin: 0;
    padding: 10px 0;
}
.ui-datepicker .ui-datepicker-title select {
}
.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
}
.ui-datepicker table {
    border: none;
}
.ui-datepicker tr {
    background: none;
    border: none;
}
.ui-datepicker tr:hover {
    background: none;
    border: none;
}
.ui-datepicker th {
    border: none;
    color: #999;
    font-weight: normal;
    padding: 4px 8px;
}
.ui-datepicker td {
    border: none;
    padding: 0;
}
.ui-datepicker td span,
.ui-datepicker td a {
    border-radius: 0;
    color: black;
    padding: 4px 8px;
}
.ui-datepicker td a.ui-state-default {
    background: none;
}
.ui-datepicker td a.ui-state-hover {
    background: #DDD;
}
.ui-datepicker td.ui-datepicker-current-day a {
    background: #BBB;
}
.ui-datepicker .ui-datepicker-buttonpane {
    margin: 0;
}
.ui-datepicker .ui-datepicker-buttonpane button {
    color: black;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
}
.ui-datepicker-row-break {
}

/* menu */
.ui-menu {
    background: white;
    border: 1px solid #BBB;
    border-radius: 2px;
    margin: 0;
    padding: 5px 0;
    z-index: 200;
}
.ui-menu .ui-menu {
}
.ui-menu .ui-menu-item {
    margin: 0;
    padding: 5px 9px;
}
.ui-menu .ui-menu-item a {
    color: black;
}
.ui-menu .ui-menu-divider {
    border-bottom: 1px solid #EEE;
}
.ui-menu .ui-state-focus,
.ui-menu .ui-state-active {
}

/* tooltip */
.ui-tooltip {
    background: black;
    border-radius: 2px;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: white;
    margin: 0;
    max-width: none;
    opacity: 0.8;
    padding: 10px 15px;
    pointer-events: none;
    white-space: nowrap;
}
