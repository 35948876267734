#main
{
    background: #F1F2F2;
}

#content
{
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.content-inset
{
    margin: 0;
    padding: 0.75em 30px;
}

@media screen and (max-width: 480px) {
    
    #content
    {
        bottom: 0 !important;
        left: 0 !important;
        overflow-x: hidden !important;
        overflow-y: auto !important;
        position: relative !important;
        right: 0 !important;
        top: 0 !important;
        width: 100% !important;
    }
    
    .content-inset
    {
        padding: 0.75em 15px;
    }
    
}
