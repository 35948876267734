.toolbar
{
    background: white;
    border-bottom: 1px solid #CCC;
    height: 61px;
    left: 0;
    padding: 20px 20px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 300;
}

.toolbar:before,
.toolbar:after
{
    clear: both;
    content: " ";
    display: block;
}

.toolbar.toolbar-front
{
    z-index: 500;
}

.toolbar .toolbar-left
{
    float: left;
    margin: 0 -5px;
    width: 75%;
}

.toolbar .toolbar-left-long
{
    width: 80%;
}

.toolbar .toolbar-right
{
    float: right;
    margin: 0 -5px;
}

.toolbar .toolbar-item
{
    float: left;
    margin: 0 5px;
    padding: 0;
}

.toolbar .toolbar-item-m
{
    width: 100px;
}

.toolbar .toolbar-item-l
{
    width: 150px;
}

.toolbar .toolbar-item-xl
{
    width: 300px;
}

.toolbar button,
.toolbar .button
{
    margin: -6px 0;
    padding: 6px 10px;
}

.toolbar .chosen-container
{
    margin: 0;
    padding: 0;
}

.toolbar form
{
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 480px) {

    .toolbar
    {
        bottom: auto;
        height: auto;
        left: auto;
        padding: 10px 15px;
        position: static;
        right: auto;
        top: auto;
    }

    .toolbar .toolbar-left,
    .toolbar .toolbar-right
    {
        float: left;
        margin: 0 -5px;
        padding: 0;
        width: 100%;
    }

    .toolbar .toolbar-item
    {
        margin: 10px 5px;
    }

}
