.fixed
{
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    right: 0;
    top: 0;
}

.with-header
{
    top: 80px !important;
}

.with-banner
{
    top: 85px !important;
}

.with-banner.with-toolbar
{
    top: 146px !important;
}

.with-map
{
    top: 201px !important;
}

.with-map.with-toolbar
{
    top: 261px !important;
}

.with-toolbar
{
    top: 61px !important;
}

@media screen and (max-width: 480px) {
    
    .fluid-mobile
    {
        bottom: 0 !important;
        left: 0 !important;
        overflow-x: hidden !important;
        overflow-y: auto !important;
        position: relative !important;
        right: 0 !important;
        top: 0 !important;
        width: 100% !important;
    }
    
}
