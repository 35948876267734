.menu
{
    position: absolute;
    top: 25px;
    min-width: 200px;
}

/** popup buttons */

.popup
{
    position: relative;
}

.popup-button
{
    display: inline-block;
    line-height: 1.5em;
    margin: -6px 0;
    padding: 6px 10px;
}

.popup-button:hover *,
.popup-button.active
{
    color: white;
}

.popup-menu
{
    border-radius: 2px 0 2px 2px !important;
    left: auto !important;
    right: 0 !important;
}

@media screen and (max-width: 480px) {
    
    .popup-menu
    {
        border-radius: 0 2px 2px 2px !important;
        left: 0 !important;
        right: auto !important;
    }
    
}
